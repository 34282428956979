import React from "react";
import { Whatsapp } from "@styled-icons/fa-brands/";
import { Container, InfoHome } from "./styles";

import Lang from '../../lang';

function Banner({ language }) {
  return (
    <Container id="home">
      <InfoHome>
        <p>
          <Lang id="banner.p" language={language} /> 
        </p>
        <strong>
          <Lang id="banner.strong" language={language} />{' '}
          <span>
            <Lang id="banner.span" language={language} />
          </span>?
        </strong>
        <a
          href="https://api.whatsapp.com/send?phone=5524981266361&text=Olá, vigorTech"
          target="blank"
        >
          <button>
            <Lang id="banner.button" language={language} />
            <Whatsapp size={25} color="#175349" />
          </button>
        </a>
      </InfoHome>
    </Container>
  );
}

export default Banner;
