import React from "react";

import { Container, Icon } from "./styles";

import pt from "../../../images/icons/icon-pt.svg";
import en from "../../../images/icons/icon-en.svg";
import es from "../../../images/icons/icon-es.svg";

function Language({ onLanguageChange, language}) {

  return (
    <Container>
      <Icon
        src={pt}
        alt="Português"
        title="Português"
        onClick={() => onLanguageChange("pt")}
        opacity={language === "pt" ? "1" : "0.4"}
      />
      <Icon
        src={en}
        alt="Inglês"
        title="Inglês"
        onClick={() => onLanguageChange("en")}
        opacity={language === "en" ? "1" : "0.4"}
      />
      <Icon
        src={es}
        alt="Espanhol"
        title="Espanhol"
        onClick={() => onLanguageChange("es")}
        opacity={language === "es" ? "1" : "0.4"}
      />
    </Container>
  );
}

export default Language;
