import { createGlobalStyle } from "styled-components";
import 'react-toastify/dist/ReactToastify.css';

const GlobalStyle = createGlobalStyle`
 *,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

p, label{
  font-family: 'Maven Pro', sans-serif;
}

html,
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
  font-family: 'Fredoka One', cursive;
}

.container {
  max-width: 90%;
  margin: 0 auto;
  padding: 0 20px;
}

h2{
  color: #175349;
  font-size: 57px;
  position: relative;
  width: 100%;
  text-align: center;
  margin-bottom: 80px;
  &:before{
    content: '';
    position: absolute;
    bottom: -20px;
    left: 0;
    right: 0;
    margin: auto;
    width: 240px;
    height: 1px;
    background: #757575;
  }
  &:after{
    content: '';
    position: absolute;
    bottom: -24px;
    left: 0;
    right: 0;
    margin: auto;
    width: 100px;
    height: 8px;
    border-radius: 4px;
    background: #00CBA9;
  }
}

`;

export default GlobalStyle;
