import React from "react";

// import { Container } from './styles';

import Header from "../components/Header";
import Footer from "../components/Footer";

function Layout({ children, onLanguageChange }) {
  return (
    <>
      <Header onLanguageChange={onLanguageChange} />
      {children}
      <Footer />
    </>
  );
}

export default Layout;
