import styled from "styled-components";

export const Container = styled.div`
  margin: 100px 0;
  padding: 0 5%;
  span {
    display: flex;
    width: 100%;
    align-items: center;
    @media (max-width: 780px) {
      flex-direction: column;
      img {
        width: 100%;
      }
    }
  }
  p {
    margin: 30px;
    text-align: left;
    font-weight: bold;
    color: #212121;
    line-height: 24px;
  }
`;

export const List = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 40px;
  img {
    border: solid 8px #e8f3fb;
  }
  p2 {
    text-align: center;
    font-weight: bold;
    color: #212121;
  }
`;

export const Item = styled.div``;
