import styled from "styled-components";

export const Container = styled.div``;
export const Modall = styled.div`
  background-color: #fff;

  max-height: 80vh;
  width: 90%;
  max-width: 600px;
  overflow-y: auto;
  color: black;
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.2);
  padding: 30px;
  border-radius: 10px;
  transition: all ease 0.4s;
  display: flex;
  flex-direction: column;
  text-align: center;
  opacity: 1;
  outline: none;
  h2 {
    font-size: 30px;
    margin-bottom: 50px;
    &:after {
      height: 5px;
      bottom: -22px;
    }
  }
  span {
    display: flex;
    justify-content: center;
    gap: 40px;
    margin: 20px 0;
    @media (max-width: 780px) {
      flex-direction: column;
    }
  }
`;
