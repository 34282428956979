import React from "react";

import { Container, List, Item } from "./styles";

import nsa from "../../../images/logos/nsa.jpg";
import stalx from "../../../images/logos/stalx.jpg";
import lauer from "../../../images/logos/lauer.jpg";
import autobama from "../../../images/logos/autobama.jpg";
import gera from "../../../images/logos/gera.jpg";
// import toniato from "../../../images/logos/toniato.jpg";
import servisa from "../../../images/logos/servisa.jpg";
import eKaizen from "../../../images/logos/eKaizen.png";

import Lang from '../../lang';

const data = [
  { img: gera, name: "Gera Sinergia" },
  { img: autobama, name: "Autobama" },
  { img: stalx, name: "STALX" },
  { img: lauer, name: "Lauer Engenharia" },
  { img: nsa, name: "NSA Tranportes" },
  { img: servisa, name: "Servi-Sá Guindastes" },
  { img: eKaizen, name: "eKaizen" },
  //{ img: toniato, name: "Grupo Toniato" },
];

function Parceiros({ language }) {
  return (
    <Container id="parceiros">
      <h2>
        <Lang id="partner.h2" language={language} /> 
      </h2>
      <List>
        {data.map((item, index) => (
          <Item key={index}>
            <img src={item.img} alt="" />
            
          </Item>
        ))}
      </List>
    </Container>
  );
}

export default Parceiros;
