import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 40px;
  margin: 100px 0 40px;
  @media (max-width: 640px) {
    flex-direction: column;
  }
  p {
    font-size: 16px;
    color: #212121;
  }
  img {
    height: 30px;
  }
  span {
    display: flex;
    gap: 10px;
  }
`;
