import React from "react";
import ReactDOM from "react-dom";
import App from "./App";

import { HeaderProvider } from "./components/Header/HeaderProvider";
import { ScrollProvider } from "./components/Header/ScrollProvider";

ReactDOM.render(
  <React.StrictMode>
    <ScrollProvider>
      <HeaderProvider>
        <App />
      </HeaderProvider>
    </ScrollProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
