import React from "react";

import { Container, List, Item } from "./styles";

import node from "../../../images/logos/node.jpg";
import reactJs from "../../../images/logos/reactJs.jpg";
import reactNative from "../../../images/logos/reactNative.jpg";
import java from "../../../images/logos/java.jpg";
import php from "../../../images/logos/php.jpg";
import python from "../../../images/logos/python.jpg";
import ruby from "../../../images/logos/ruby.jpg";

import Lang from '../../lang';

const dataPrimeiro = [
  { img: node, name: "NODE JS" },
  { img: reactJs, name: "REACT" },
  { img: reactNative, name: "REACT NATIVE" },
];

const dataSegundo = [
  { img: java, name: "JAVA" },
  { img: python, name: "PYTHON" },
  { img: ruby, name: "RUBY" },
  { img: php, name: "PHP" },
];

function Tecnologias({ language }) {
  return (
    <Container id="tecnologias">
      <h2>
        <Lang id="tech.h2" language={language} /> 
      </h2>
      <p>
        <Lang id="tech.p1" language={language} /> 
      </p>
      <List>
        {dataPrimeiro.map((item, index) => (
          <Item key={index}>
            <img src={item.img} alt="" />
            
          </Item>
        ))}
      </List>
      <p>
        <Lang id="tech.p2" language={language} /> 
      </p>
      <List>
        {dataSegundo.map((item, index) => (
          <Item key={index}>
            <img src={item.img} alt="" />
            
          </Item>
        ))}
      </List>
    </Container>
  );
}

export default Tecnologias;
