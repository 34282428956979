import React from "react";

import { Whatsapp, Facebook, Instagram, Linkedin } from "@styled-icons/fa-brands/";

import logo from "../../images/logo_v.svg";

import { Container } from "./styles";

function Footer() {
  return (
    <Container>
      <p>contato@vigortech.io</p>
      <img src={logo} alt="" />

      <span>
       <a href="https://api.whatsapp.com/send?phone=5524981266361&text=Ol%C3%A1,%20vigorTech"><Whatsapp size={25} color="#212121" /> </a>
       <a href="https://www.facebook.com/Vigortechsoftware"><Facebook size={25} color="#212121" /></a>
       <a href="https://www.instagram.com/vigortech.software/"><Instagram size={25} color="#212121" /></a>
       <a href="https://www.linkedin.com/in/vigortech-softwarehouse-a915a71b9/"><Linkedin size={25} color="#212121" /></a>
      </span>
    </Container>
  );
}

export default Footer;
