import React from "react";

import { Container } from "./styles";

import img from "../../../images/emp.svg";

import Lang from '../../lang';

function Empresa({ language }) {
  return (
    <Container id="empresa">
      <h2>
        <Lang id="company.h2" language={language} /> 
      </h2>
      <span>
        <img src={img} alt="" />
        <p>
          <Lang id="company.p" language={language} /> 
        </p>
      </span>
    </Container>
  );
}

export default Empresa;
