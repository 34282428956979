import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  gap: 10px;
`;

export const Icon = styled.img`
  width: 20px;
  height: 20px;
  cursor: pointer;
  opacity: ${(props) => props.opacity};
  transition: all ease 0.3s;
`;
