// Dependencies
import React, { useState } from 'react';
import axios from 'axios';
import swal from 'sweetalert';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { Input } from '@rocketseat/unform';

// Components
import MaskInput from '../../MaskInput';
import Button from '../../Button';
import Lang from '../../lang';

// Styles
import { FormStyled, FormRow, Field } from '../../../styles/form';

export default function Contact({ onClose, language }) {
  // States
  const [loading, setLoading] = useState(false);

  // Validators
  const Schema = Yup.object().shape({
    name: Yup.string().required('Nome obrigatório'),
    email: Yup.string()
      .email('Insira um e-mail válido')
      .required('Email obrigatório'),
    telephone: Yup.string(),
    message: Yup.string().required('Mensagem obrigatória'),
  });

  // Async Functions
  async function handleSubmit(data, { resetForm }) {
    setLoading(true);

    const api_key = '480aa8978db7c5ce8cb3598ab209b3f0-29b7488f-a985b7c9';
    const domain = 'mg.sistemansa.com';
    const auth ={
      username: 'api',
      password: api_key,
    }

    const from = 'VigorTech<no-reply@vigortech.com>';
    const to = 'contato@vigortech.io';
    const subject = 'Novo Contato!';
    const html = `        
      <h1>O cliente, ${data.name}, entrou em contato!</h1>
      <hr>
        <strong>Mensagem:</strong><p>${data.message}</p>
      <hr>
      <h2>Informações do cliente:</h2>
      <ul>
        <li>
          <p>
            <strong>Nome:</strong>
            ${data.name}
          </p>
        </li>
        <li>
          <p>
            <strong>Email:</strong>
            ${data.email}
          </p>
        </li>
        <li>
          <p>
            <strong>Telefone:</strong>
            ${data.telephone || '-'}
          </p>
        </li>
      </ul> 
    `;

    const mailData = {
      from,
      to,
      subject,
      html,
    };

    await axios({
      method: 'post',
      url: `https://api.mailgun.net/v3/${domain}/messages`,
      auth,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      params: {
        ...mailData
      },
      })
      .then((e) => {
        console.log(e);
        setLoading(false);
        resetForm();
        onClose();

        swal({
            title: 'Mensagem enviada com sucessso!',
            text: 'Entraremos em contato em breve.',
            icon: 'success',
            confirmButtonColor: '#00CBA9',
            confirmButtonText: 'Ok',      
        });
      })
      .catch (() => {
        setLoading(false);
        onClose();
        toast.error(`Um erro aconteceu, tente novamente mais tarde.`);
      })  
  }
  
  return (
    <FormStyled
      schema={Schema}
      onSubmit={handleSubmit}
    >
      <ul>
        <FormRow>
          <Field>
            <label htmlFor="name">
              <Lang id="contact.form.name" language={language} /> 
              <b>*</b>
            </label>
            <Input id="name" name="name" />
          </Field>
          <Field>
            <label htmlFor="telephone">
              <Lang id="contact.form.telephone" language={language} /> 
            </label>
            <MaskInput id="telephone" name="telephone" />
          </Field>
        </FormRow>
        <FormRow>
          <Field>
            <label htmlFor="email">
              <Lang id="contact.form.email" language={language} /> 
              <b>*</b>
            </label>
            <Input id="email" name="email" type="email" />
          </Field>
        </FormRow>
        <FormRow>
          <Field>
            <label htmlFor="message">
              <Lang id="contact.form.message" language={language} /> 
              <b>*</b>
            </label>
            <Input multiline id="message" name="message" />
          </Field>
        </FormRow>
        <FormRow>
          <Field className="submit">
            <Button loading={loading} type="submit" color="#00CBA9">
              <strong>
                <Lang id="contact.form.button" language={language} /> 
              </strong>
            </Button>
          </Field>
        </FormRow>
      </ul>
    </FormStyled>
  );
}
