import React from "react";


// Components
import Modal from "../../Modal";
import MailForm from "../MailForm";

// Styles
import { Modall } from "./styles";
import { Whatsapp } from "@styled-icons/fa-brands/";
import { EmailOutline } from "@styled-icons/evaicons-outline/";

import Lang from '../../lang';

function ContatoModal({ open, handleClose, language }) {
  const close = () => {
    handleClose();
  };

  return (
    <>
      <Modal open={open} close={close}>
        <Modall>
          <h2>
            <Lang id="contact.h2" language={language} /> 
          </h2>
          <MailForm onClose={handleClose} language={language} />
          <span>
            <p>
              <EmailOutline size={20} color="#212121" /> contato@vigortech.io
            </p>
            <p>
            <a href="https://api.whatsapp.com/send?phone=5524981266361&text=Ol%C3%A1,%20vigorTech"><Whatsapp size={20} color="#212121" /></a> (24) 98126-6361
            </p>
          </span>
        </Modall>
      </Modal>
    </>
  );
}

export default ContatoModal;
