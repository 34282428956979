/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { Link } from "react-scroll";

import ContatoModal from "../../components/Home/ContatoModal";

import { Container } from "./styles";

import Logo from "../../images/logo2.svg";

import { useHeaderContext } from "./HeaderProvider";
import Language from "./Language";

import Lang from '../lang';

function Header({ onLanguageChange }) {
  const { isVisible } = useHeaderContext();
  const [openInit, setOpenInit] = useState(false);
  const [language, setLanguage] = useState("pt");

  const handleClose = () => {
    setOpenInit(false);
  };

  const [scroll, setScroll] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 80);
    });
  }, []);

  return (
    <>
      <ContatoModal open={openInit} handleClose={handleClose} language={language} />
      <Container
        style={{
          transform: `${isVisible ? "translateY(0)" : "translateY(-100%)"}`,
        }}
        className={scroll ? "fixed" : ""}
      >
        <div className="container">
          <Link
            activeClass="active"
            to="home"
            spy={true}
            smooth={true}
            offset={-40}
            duration={300}
          >
            <img src={Logo} alt="" />
          </Link>
          <nav>
            <Link
              activeClass="active"
              to="tecnologias"
              spy={true}
              smooth={true}
              offset={-10}
              duration={300}
            >
              <Lang id="header.link1" language={language} /> 
            </Link>
            <Link
              activeClass="active"
              to="parceiros"
              spy={true}
              smooth={true}
              offset={-10}
              duration={300}
            >
              <Lang id="header.link2" language={language} /> 
            </Link>
            <a onClick={() => setOpenInit(true)}>
              <Lang id="header.link3" language={language} /> 
            </a>
          </nav>
          <Language 
            onLanguageChange={(e) => {
              setLanguage(e);
              onLanguageChange(e);
            }}
            language={language} 
          />
        </div>
      </Container>
    </>
  );
}

export default Header;
