import styled from "styled-components";

import bg from "../../../images/bghome.jpg";

export const Container = styled.section`
  width: 100%;
  height: 800px;
  background: url(${bg}) no-repeat bottom #00cba9;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 5%;
  margin-bottom: 60px;
  @media (max-width: 640px) {
    height: 640px;
    background: url(${bg}) no-repeat center bottom #00cba9;
    background-size: cover;
  }
`;

export const InfoHome = styled.div`
  width: 100%;
  max-width: 650px;
  a {
    text-decoration: none;
  }
  p {
    @media (max-width: 500px) {
      -webkit-text-stroke-width: 0.5px;
    }
    -webkit-text-stroke-color: #525252;
    font-size: 30px;
    color: #fff;
    font-weight: 500;
  }
  strong {  
    @media (max-width: 500px) {
      -webkit-text-stroke-width: 1px;
    }
    -webkit-text-stroke-color: #525252;
    font-size: 66px;
    color: #fff;
    display: block;
    margin-bottom: 60px;
    span {
      color: #39fcdd;
    }
    @media (max-width: 640px) {
      font-size: 50px;
    }
  }
  button {
    background: #fff;
    height: 50px;
    border-radius: 25px;
    padding: 0 20px;
    font-family: "Fredoka One", cursive;
    font-size: 18px;
    color: #00cba9;
    border: 0;
    box-shadow: 0 15px 16px rgba(0, 0, 0, 0.16);
    outline: none;
    transition: all ease 0.2s;
    cursor: pointer;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    svg {
      margin-left: 20px;
    }
    &:hover {
      transform: scale(0.95);
    }
  }
`;
