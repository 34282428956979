// Dependencies
import React, { useState, useEffect, useRef } from 'react';
import MaskedInput from 'react-input-mask';
import PropTypes from 'prop-types';
import { useField } from '@rocketseat/unform';

export default function MaskInput({ name, ...inputProps }) {
  // Props from Unform
  const { fieldName, registerField, defaultValue, error } = useField(name);

  // States
  const [value, setValue] = useState(defaultValue && defaultValue);
  const [mask, setMask] = useState('');

  // Refs
  const ref = useRef();

  // React Hooks
  useEffect(() => {
    switch (name) {
      case 'telephone':
        setMask('(99)99999-9999');
        break;
      case 'postal_code':
        setMask('99999-999');
        break;

      case 'workload':
        if (value) {
          if (value.length) {
            setMask('999 h');
          } else {
            setMask('999h');
          }
        } else {
          setMask('999h');
        }
        break;

      default:
        break;
    }
  }, [name, value]);

  useEffect(() => setValue(defaultValue), [defaultValue]);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: ref.current,
      path: 'props.value',
      setValue() {
        setValue('');
      },
      clearValue() {
        setValue('');
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref.current, fieldName]);

  // Functions
  function beforeMaskedValueChange(newState) {
    const newValue = newState.value
      .replace('-', '')
      .replace('(', '')
      .replace(')', '');
    setValue(newValue);
    return newState;
  }

  return (
    <>
      <MaskedInput
        value={value || ''}
        mask={mask}
        maskChar=" "
        name={fieldName}
        ref={ref}
        type="text"
        beforeMaskedValueChange={beforeMaskedValueChange}
        onChange={e => setValue(e.target.value)}
        {...inputProps}
      />
      {error && <span>{error}</span>}
    </>
  );
}

// Props
MaskInput.propTypes = {
  name: PropTypes.string.isRequired,
};
