import styled from "styled-components";

export const Container = styled.header`
  width: 100%;
  height: 80px;
  position: fixed;
  top: 0;
  left: 0;
  transition: all 0.3s ease-in-out;
  z-index: 99;
  @media (max-width: 780px) {
    height: auto;
  }
  .container {
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 780px) {
      flex-direction: column;
      justify-content: center;
    }
    img {
      height: 40px;
      cursor: pointer;
      @media (max-width: 640px) {
        height: 30px;
        margin: 10px 0;
      }
    }
  }
  nav {
    display: flex;
    @media (max-width: 640px) {
      width: 100%;
      justify-content: space-between;
    }
    a {
      color: #fff;
      font-size: 16px;
      font-weight: bold;
      text-transform: uppercase;
      text-decoration: none;
      margin-left: 40px;
      border-bottom: solid 3px transparent;
      transition: color 0.2s;
      padding: 10px 0;
      font-family: "Maven Pro", sans-serif;
      cursor: pointer;
      &:first-child {
        margin-left: 0;
      }
      &:hover,
      &.active {
        color: #175349;
      }
      @media (max-width: 640px) {
        font-size: 14px;
        margin: 0;
      }
    }
  }
  &.fixed {
    width: 100%;
    height: 80px;
    position: fixed;
    top: 0;
    background: #00cba9;
    z-index: 99;
    box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.03);
    @media (max-width: 780px) {
      height: auto;
    }
    nav {
      a {
        font-size: 16px;
      }
    }
  }
`;
