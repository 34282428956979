import styled from "styled-components";
import { Form } from "@rocketseat/unform";

export const FormStyled = styled(Form)`
  width: 100%;
  max-width: 650px;
  margin-bottom: 16px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: left;

  ul {
    width: 100%;
    max-width: 650px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

export const FormRow = styled.li`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  & + li {
    margin-top: 20px;
  }
`;

export const Field = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin: 0;

  & + div {
    margin-left: 20px;
  }

  &.submit {
    align-items: center;
  }

  label {
    font-size: 16px;
    font-weight: bold;
    color: #333;
    margin-bottom: 5px;

    @media (max-width: 500px) {
      font-size: 12px;
    }

    b {
      color: #de264c;
    }
  }

  span {
    color: #de264c;
    position: absolute;
    bottom: -36px;
    left: 8px;

    font-size: 12px;
    white-space: nowrap;
  }

  select {
    width: 100%;
    height: 40px;
    padding: 0 15px;
    background: #fff;
    border: 2px solid #ddd;
    border-radius: 4px;
    font-size: 14px;

    &:focus {
      border: 2px solid #00cba9;
    }

    &:disabled {
      background: #ddd;
      cursor: default;
    }
  }

  input {
    width: 100%;
    height: 40px;
    padding: 0 15px;
    background: #fff;
    border: 2px solid #ddd;
    border-radius: 4px;
    font-size: 14px;

    &[type="checkbox"] {
      width: 14px;
      height: 14px;
      margin: 0;
      padding: 0;
    }

    &:focus {
      border: 2px solid #00cba9;
    }

    &:disabled {
      background: #ddd;
      cursor: default;
    }
  }

  textarea {
    width: 100%;
    height: 80px;
    padding: 15px;
    background: #fff;
    border: 2px solid #ddd;
    border-radius: 4px;
    margin-top: 8px;
    resize: none;

    &:focus {
      border: 2px solid #00cba9;
    }

    &:disabled {
      background: #ddd;
      cursor: default;
    }
  }
`;
