import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";

import Home from "../pages/Home";

export default function Routes({ editlanguage }) {
  return (
    <BrowserRouter langtest={editlanguage}>
      <Switch>
        <Route path="/" exact component={Home} />
        {/* <PrivateRoute
            authed={logado}
            path="/inventory"
            exact
            component={Home}
          /> */}
      </Switch>
    </BrowserRouter>
  );
}

//   function PrivateRoute({ component: Component, authed, ...rest }) {
//     return (
//       <Route
//         {...rest}
//         render={(props) =>
//           authed === true ? (
//             <Component {...props} />
//           ) : (
//             <Redirect to={{ pathname: "/login" }} />
//           )
//         }
//       />
//     );
//   }
