import styled from "styled-components";

export const Container = styled.div`
  margin: 80px 0;
  padding: 0 5%;
`;

export const List = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 40px;
  img {
    border: solid 8px #e8f3fb;
  }
  p {
    text-align: center;
    font-weight: bold;
    color: #212121;
  }
`;

export const Item = styled.div``;
