import React, { useState } from "react";

import Layout from "../../layout";
import Empresa from "../../components/Home/Empresa";
import Banner from "../../components/Home/Banner";
import Parcerios from "../../components/Home/Parceiros";
import Tecnologias from "../../components/Home/Tecnologias";

// import { Container } from "./styles";

function Home() {
  const [language, setLanguage] = useState("pt");

  return (
    <Layout onLanguageChange={setLanguage} >
      <Banner language={language}/>
      <Empresa language={language}/>
      <Tecnologias language={language}/>
      <Parcerios language={language}/>
    </Layout>
  );
}

export default Home;
