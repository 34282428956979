import React from "react";

import locale_en from "./en.json";
import locale_pt from "./pt.json";
import locale_es from "./es.json";

function Lang({ language, id }) {
  const data = {
    pt: locale_pt,
    en: locale_en,
    es: locale_es,
  };

  console.log(id, language);

  return (
    <>
      {data[language][id]}
    </>
  );
}

export default Lang;
