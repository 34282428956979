// Dependencies
import styled from 'styled-components';

// StyledComponents
export const ButtonStyled = styled.button`
  width: 100%;
  max-width: 350px;
  height: 40px;
  background: none;
  transition: background 0.8s;
  outline: none;

  border: 2px solid ${props => props.color};
  border-radius: 10px;

  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 990px) {
    min-width: 100%;
  }

  strong {
    font-size: 16px;
    font-weight: bold;
    color: ${props => props.color};
  }

  &:hover {
    cursor: pointer;
    background: ${props => props.color};
    color: #fff;
    border: none;

    strong {
      color: #fff;
    }
  }

  &:disabled {
    background: ${props => props.color};
    cursor: default;

    strong {
      color: #fff;
    }
  }
`;
